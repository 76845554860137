.used_audience_modal_content{
  padding: 32px 80px;
  .warn_icon{
    width: 65px;
    height: 65px;
    border-radius: 50%;
    padding:17px;
    background-color:#FFF9ED ;
  }
  .desc_wrapper{
    max-width:310px;
    margin: 16px 0 32px;
    .heading{
      color:  #262626;
      text-align: center;
      font-size: 16px;
      font-weight: 700;
      margin: 0;
    }
    .desc{
      color: #757575;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
    }
  }
}