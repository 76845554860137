.audience-status {
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  width: fit-content;
  height: fit-content;

  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;


  &.available {
    background: #F1FDE8;
    color: #5ABB82;
  }

  &.pending {
    background: #EAFFFE;
    color: #007B94;
  }

  &.error {
    background: #FFF0F1;
    color: #FF2D38;
  }
  &.in_use {
    background: #FFF9ED;
    color: #FA8C16;
  }
}
