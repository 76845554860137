.ManageAvatar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 0 auto;
  .file_input{
    &:disabled{
      cursor:not-allowed;
      color:#bebebe
    }
  }
  .AvatarWrapper {
    @media screen and (min-width: 3200px) {
      width: 200px;
    }
    .Avatar {
      @media screen and (min-width: 3200px) {
        width: 200px;
        height: 200px;
      }
      img {
        @media screen and (min-width: 3200px) {
          width: 200px;
        }
      }
    }
  }

  &__avatar {
    margin: 1rem;
  }
  button {
    margin-bottom: 0.5rem;
  }
  .custom-file-upload {
    font-size: 16px;
    font-weight: 700;
    color: #44c2e6;
    cursor: pointer;

    @media screen and (min-width: 3200px) {
      font-size: 26px;
    }
  }
  .file_input {
    display: none;
  }
  .avatar {
    border: 1px solid #aab2b5;
  }
  img {
    object-fit: cover;
  }
}

.image_cropper {
  position: relative;
  overflow: hidden;

  &__container {
    padding: 1rem;
  }

  &__crop_wrapper {
    position: relative;
  }

  &__buttons_wrapper {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
  }

  .reactEasyCrop_Contain {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
